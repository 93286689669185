









































































import { useMainStore } from '@/store';
import { FormDefineItem } from '@/views/User/api';
import { computed, defineComponent, PropType, ref, watchEffect } from '@vue/composition-api';
import { Message } from 'element-ui';
import { HttpRequestOptions } from 'element-ui/types/upload';
import { clone, isNaN } from 'lodash';
import { uploadFile, UploadResult } from '../api';

export default defineComponent({
  props: {
    define: {
      type: Object as PropType<FormDefineItem>,
      required: true,
    },
    value: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { state } = useMainStore();

    const onInput = (val: number | string | undefined) => {
      const target = clone(props.value);
      const value = val;
      if (props.define.type === 'phone' || props.define.type === 'number') {
        if (isNaN(Number(val))) {
          return;
        }
      }
      target[props.define.field] = value;
      emit('input', target);
    };

    watchEffect(() => {
      if (
        props.define.type === 'select' &&
        typeof props.value[props.define.field] === 'undefined'
      ) {
        onInput(props.define.options?.[0]);
      }
    });

    const file = ref<UploadResult>();

    return {
      file,
      oss: computed(() => state.oss),
      rules: computed(() => {
        let rules: any[] = [];
        switch (props.define.type) {
          case 'phone':
            rules = rules.concat([
              {
                pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
                message: '手机号格式不对',
                trigger: 'blur',
              },
            ]);
            break;
        }

        if (props.define.required) {
          rules.push({
            required: true,
            message: `请输入${props.define.name}`,
            trigger: 'blur',
          });
        }

        return rules;
      }),
      options: computed(() => props.define.options || []),
      onInput,
      onUpload: async (e: { target: HTMLInputElement }) => {
        try {
          if (e.target.files && e.target.files[0]) {
            const res = await uploadFile(e.target.files[0]);
            file.value = res[0];
            onInput(JSON.stringify(res.map(f => f.url)));
          }
        } catch (error) {
          Message.error(error || '上传失败');
        }
      },
    };
  },
});
