



































import { useMainStore } from '@/store';
import { ContentItem } from '@/store/api/model';
import { computed, defineComponent, getCurrentInstance, ref, watch } from '@vue/composition-api';
import { PropType } from '@vue/composition-api';
import { ImageItem } from '../store/api/model';

export default defineComponent({
  props: {
    content: {
      required: true,
      type: Array as PropType<ContentItem[]>,
    },
  },
  setup(props) {
    const { state } = useMainStore();
    const images = computed(() =>
      props.content
        ? props.content.flatMap(ctx => {
            const images =
              ctx.images?.map<ImageItem & { hash?: string }>(i => ({
                ...i,
                url: `${state.oss}${i.url}`,
              })) || [];

            if (images[0]) {
              images[0].hash = ctx.title;
            }

            return images;
          })
        : [],
    );

    const current = ref(props.content[0].title);

    watch(current, val => {
      const view = document.getElementById(val);
      if (view) {
        console.log(view);
        view.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    });

    return { current, images };
  },
});
