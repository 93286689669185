





















import { computed, defineComponent, onMounted } from '@vue/composition-api';
import { useMainStore } from '@/store';
import Content from '@/components/Content.vue';
import UserForm from '@/components/Form/index.vue';
import { NavModule } from '@/store/api/model';

export default defineComponent({
  components: { Content, UserForm },
  setup(_, { root }) {
    const main = useMainStore();

    const current = computed(() =>
      main.functionModules.find(m => m.id.toString() === root.$route.params.id),
    );

    onMounted(() => {
      // const id =
    });

    return {
      onClick: (item: NavModule) => {
        if (item.id.toString() !== root.$route.params.id) {
          root.$router.push({ name: root.$route.name!, params: { id: item.id.toString() } });
        }
      },
      functions: computed(() => main.functionModules),
      current,
      oss: computed(() => main.state.oss),
    };
  },
});
