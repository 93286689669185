














































import { computed, defineComponent, ref, watch, watchEffect } from '@vue/composition-api';
import { useMainStore } from '@/store';
import { useApi } from '@/util/useApi';
import { getFormDefineById, submitForm } from './api';
import FormItem from './components/FormItem.vue';
import { ElForm } from 'element-ui/types/form';
import { Notification } from 'element-ui';

export default defineComponent({
  components: { FormItem },
  props: {
    title: { type: String, required: true },
    icon: { type: String, required: true },
    formId: { type: String, required: true },
  },
  setup(props, { root, refs }) {
    const main = useMainStore();

    const { result: define, refresh } = useApi(
      (id: string) => getFormDefineById(id),
      undefined,
      false,
    );
    const submited = ref(false);
    const value = ref<Record<string, any>>({});
    watch(
      () => main.state.user,
      user => {
        if (user) {
          value.value = {
            organization: user.company,
            contact: user.nickname,
            phone: user.username,
          };
        }
      },
      { immediate: true },
    );
    watch(
      () => props.formId,
      id => {
        if (id) {
          refresh(id);
          submited.value = false;
        }
      },
      { immediate: true },
    );

    return {
      define,
      value,
      submited,
      onSubmit: async () => {
        const form = refs.form as ElForm;
        try {
          if (main.state.user) {
            if ((await form.validate()) && value.value && define.value?.id) {
              await submitForm(Number(props.formId), value.value);
            }
            Notification.success('已提交申请');
            submited.value = true;
          } else {
            main.state.showLogin = true;
          }
        } catch {
          //
        }
      },
    };
  },
});
