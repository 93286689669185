import { request } from '@/util/request';
import { FormDefine } from '../../../views/User/api/model';
import { UploadResult } from './model';

export * from './model';

export function getFormDefineById(id: string) {
  return request<FormDefine>(`/strapi/form-defines/${id}`);
}

export function submitForm(formId: number, value: Record<string, string | number>) {
  return request(`/strapi/forms/`, {
    method: 'POST',
    data: {
      form_define: formId,
      values: value,
    },
  });
}

export function uploadFile(file: File) {
  const data = new FormData();
  data.append('files', file);
  return request<UploadResult[]>(`/strapi/upload`, {
    method: 'POST',
    data,
  });
}
